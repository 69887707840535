const Layout = () => import('@/views/Layout')
const Home = () => import('@/views/home')
const Product = () => import('@/views/product/index.vue')
const ProductList = () => import('@/views/product/components/product-list.vue')
const ProductDetail = () => import("@/views/product/components/product-detail")
const SubCategory = () => import('@/views/category/sub')
const News = () => import("@/views/news/index.vue")
const NewsDetail = () => import('@/views/news/components/news-detail')
const Link = () => import("@/views/link/index.vue")
const Company = () => import("@/views/company/index.vue")
const Honor = () => import("@/views/honor/index.vue")
const Contact = () => import("@/views/company/contact.vue")
const ProductAdv = () => import("@/views/productadv/index.vue")
const ProductAdvDetail = () => import("@/views/productadv/components/solve-info.vue")
const CaseW = () => import("@/views/casew/index.vue")
const CaseWDetail = () => import("@/views/casew/components/case-detail.vue")
// 路由规则
export const routes = [
  // 一级路由布局容器
    {
    path: '/',
    component:() => import('@/views/Layout'),
    children: [
      {
        path: '/', component: () => import('@/views/home'),
        meta: {
          title: '杭州建航物流科技官网',
          content: {
            keywords: "立体仓库、建航物流科技、仓储软件、提升输送机",
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。为各个不同行业服务，在供应链的不同环节，通过向客户提供国际先进的物流管理技术、经验和高品质的物流相关系统，通过持续的物流改善，协助客户在降低其物流总成本条件下实现既定的客户服务水平，提高企业物流能力和市场核心竞争能力。'
          }
        }
      },
      {
        path: '/product', component: Product,
        meta: {
          title: '产品中心',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        },
        children: [
          {
            path: ':id', component: () => import('@/views/product/components/product-list.vue'),
            meta: {
              title: '产品中心',
              content: {
                keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
                description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
              }
            }
          }
        ]
      },
      {path: '/product/detail/:id', component: () => import("@/views/product/components/product-detail"),
        meta: {
          title: '产品详情',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
      {
        path: "/news", component: News,
        meta: {
          title: '资讯中心',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        },
        children: [
          {
            path: ":id",
            component: () => import("@/views/news/components/news-list.vue"),
            meta: {
              title: '资讯中心',
              content: {
                keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
                description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
              }
            }
          }
        ],
      },
      {path: '/news/detail/:newsId', component: NewsDetail,
        meta: {
          title: '资讯详情',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
      {
        path: "/link", component: Link,
        meta: {
          title: '人才',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        },
        children: [
          {
            path: ":flag",
            component: () => import("@/views/link/components/link-about.vue"),
            meta: {
              title: '人才',
              content: {
                keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
                description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
              }
            }
          }
        ],
      },
      {path: "/company", component: Company,
        meta: {
          title: '企业概述',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
      {path: "/honor", component: Honor,
        meta: {
          title: '企业荣誉',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
      {path: "/contactUs", component: Contact,
        meta: {
          title: '关于我们',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
      {path: "/productadv", component: ProductAdv,
        meta: {
          title: '企业概述',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
      {path: "/productadv/detail/:link", component: ProductAdvDetail,
        meta: {
          title: '方案详情',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }
      },
      {
        path: "/casew", component: CaseW,
        meta: {
          title: '项目案例',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        },
        children: [
          {
            path: "/casew",
            component: () => import("@/views/casew/index.vue"),
            meta: {
              title: '项目案例',
              content: {
                keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
                description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
              }
            }
          },
          {
            path: ":id",
            component: () => import("@/views/casew/components/case-list.vue"),
            meta: {
              title: '项目案例',
              content: {
                keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
                description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
              }
            }
          }
        ]
      },
      {path: '/casew/detail/:caseId', component: CaseWDetail,
        meta: {
          title: '案例详情',
          content: {
            keywords: '立体仓库、建航物流科技、仓储软件、提升输送机',
            description:'杭州建航物流科技有限公司是全面物流解决方案的提供者。'
          }
        }},
    ]
  },
  {
    path: '/:pathMatch(.*)*',  // 匹配所有未定义的路由
    redirect: '/'  // 重定向到首页
  }
]

