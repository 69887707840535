import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from 'vue-meta-info'
import VueLazyComponent from 'vue-lazyload'
// 导入自己UI组件库
// import UI from '@/components/library'
//导入视频css文件
import "video.js/dist/video-js.css";
// 1. 重置样式的库
import 'normalize.css'
// 2. 自己项目的重置样式和公用样式
import '@/assets/styles/common.less'

// 导入ElementPlus
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';

// 导入font-awesome6.0
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'



library.add(fas, fab)

// 引入动画组件
import animated from 'animate.css'

// // 引入模板相关
// import 'bootstrap/dist/css/bootstrap.min.css'
const app =createApp(App)
app.use(store).use(router).use(ElementPlus).use(animated).use(MetaInfo)
app.use(VueLazyComponent, {
  preLoad: 1.1,
  error: 'dist/error.png',
  loading: 'loading.gif',
  attempt: 1
})
app.component('font-awesome-icon', FontAwesomeIcon);
app.config.warnHandler = () => null
app.mount('#app')
