// 默认9个顶级分类
export const topCategory = []

// 默认9个顶级分类
export const topMenu = [
  '首页',
  '解决方案',
  '行业案例',
  '产品中心',
  '新闻资讯',
  '联系我们',
]
export const orderStatus = []

export const cancelReason = []
