import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'

import {routes} from "@/router/routerConfig";


// vue2.0 new VueRouter({}) 创建路由实例
// vue3.0 createRouter({}) 创建路由实例
const routerHistory = createWebHistory();
const router = createRouter({
  // 使用hash的路由模式
  // history: createWebHashHistory(),
  history: routerHistory,
  routes,
  // 每次切换路由的时候滚动到页面顶部
  // scrollBehavior() {
  //   // vue2.0  x  y  控制
  //   // vue3.0  left  top 控制
  //   return {left: 0, top: 1}
  // }
})

router.beforeEach((to, from, next) => {
  // 需要登录的路由：地址是以 /member 开头
  // const { profile } = store.state.user
  // if (!profile.token && to.path.startsWith('/member')) {
  //   return next('/login?redirectUrl=' + encodeURIComponent(to.fullPath))
  // }
  // next()

  // 根据屏幕分辨率执行跳转逻辑
  // const screenWidth = window.screen.width;
  // if (screenWidth <= 768) {
  //   // 如果分辨率小于等于768，跳转到域名A
  //   //服务器配置
  //   window.location.href = 'http://localhost/web/m';
  // }
  let isContain = false;
  for (let path of filterPath) {
    if (to.path.startsWith(path)) {
      isContain = true
      break
    }
  }
  if (!isContain && to.meta && to.meta.content) {
    const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
      existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
      existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement('meta');
    descriptionMeta.setAttribute('name', 'description');
    descriptionMeta.setAttribute('content', to.meta.content.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签
  }
  next();
});
router.afterEach((to, from) => {
  originalPath.forEach((item) => {
    if (to.path.startsWith(item + "/" + "detail") || to.path.startsWith("/link") || to.path === item) {
      window.scrollTo(0, 0);
    }
  })
  router.afterEach((to, from, failure) => {
    if (process.env.NODE_ENV === "development" && failure) {
      console.log(failure)
      console.log("发生了导航错误！")
    }
  })
})
;
export default router


const originalPath = [
  "/", "/productadv", "/casew", "/product", "/news", "/company", "/contactUs", "/honor"
]

const filterPath = [
  "/product/"
]
