<template id="app">
  <div>
    <h1 hidden="">杭州建航物流有限公司官网</h1>
    <el-backtop :right="40" :bottom="100"/>
    <div class="custom-backtop" @click.prevent>
      <el-popover
        class="weixingQRCodeId"
        placement="top-start"
        :width="250"
        trigger="hover"
      >
        <template #reference>
          <div class="icon-wrapper">
            <font-awesome-icon icon="fa-brands fa-weixin"/>
          </div>
        </template>
        <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/weixing.jpg" alt="微信公众号">
      </el-popover>
    </div>
    <div class="tiktop-backtop" @click.prevent>
      <el-popover
        class="tiktokQRCodeId"
        placement="top-start"
        :width="250"
        trigger="hover"
      >
        <template #reference>
          <div class="icon-wrapper">
            <font-awesome-icon icon="fa-brands fa-tiktok"/>
          </div>
        </template>
        <img src="https://jianhangtech.oss-cn-hangzhou.aliyuncs.com/introduce/TikTok.jpg" alt="抖音账号">
      </el-popover>
    </div>
  </div>
  <!-- 一级路由 -->
  <RouterView/>
</template>


<style lang="less">
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 99;

  .icon-wrapper {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    z-index: 99;
  }


  .custom-backtop {
    position: fixed;
    right: 40px;
    bottom: 200px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    --el-backtop-bg-color: #ffffff;
    --el-backtop-text-color: #409eff;
    --el-backtop-hover-bg-color: #f2f6fc;
    box-shadow: var(--el-box-shadow-lighter);
    z-index: 99;
    /* 其他样式 */
  }

  .tiktop-backtop {
    position: fixed;
    right: 40px;
    bottom: 150px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #409eff;
    display: flex;
    align-items: center;
    justify-content: center;
    --el-backtop-bg-color: #ffffff;
    --el-backtop-text-color: #409eff;
    --el-backtop-hover-bg-color: #f2f6fc;
    box-shadow: var(--el-box-shadow-lighter);
    z-index: 99;
    /* 其他样式 */
  }
}
</style>
<script setup>
import {onMounted} from "vue";

onMounted(() => {
  document.dispatchEvent(new Event('custom-render-trigger'))
})
</script>
