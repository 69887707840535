// 分类模块
import {topMenu} from '@/api/constants'
import {findAllMenu} from '@/api/menu'

export default {
  namespaced: true,
  state() {
    return {
      // 分类信息集合，依赖topMenu重新设置，保证初始化就要数据，不至于白屏
      list: topMenu.map(item => ({name: item}))
    }
  },
  // 修改分类函数
  mutations: {
    // payload 所有的分类集合
    setList(state, payload) {
      state.list = payload
    },
    // 定义show和hide函数，控制当前分类的二级分类显示和隐藏
    show(state, count) {
      const currMenu = state.list.find(item => item.count === count)
      currMenu.open = true
    },
    hide(state, count) {
      const currMenu = state.list.find(item => item.count === count)
      currMenu.open = false
    }
  },
  // 获取分类函数
  actions: {
    async getList({commit}) {
      // 获取分类数据
      const data = await findAllMenu()
      // 给每个分类加上控制二级分类显示隐藏的数据
      if (data.data.list) {
        data.data.list.forEach(top => {
          top.open = false
        })
      }
      // 修改分类数据
      commit('setList', data.data.list)
    }
  }
}
